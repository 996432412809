import "./App.css";

import { Route, Routes } from "react-router-dom";
import AdminRoute from "./utils/security/AdminRoute";
import AuthContext from "./context/auth_context";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import store from "./reducers";
import { securityActions } from "./reducers/security";

//pages admin
import SeoPagesForm from "./pages/SeoPagesForm";
import SeoPagesList from "./pages/SeoPagesList";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";

//css
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/template/index.scss";

import "./assets/Admin/global.scss";
import "./assets/responsive.scss";

//products
import Product from "./pages/Products/Product";
import ProductEdit from "./pages/Products/ProductEdit";

import OrderList from "./pages/Products/OrderList";
import OrderDetail from "./pages/Products/OrderDetail";
import ProductList from "./pages/Products/ProductList";
import ProductCheck from "./pages/ProductCheck";
import ProductSync from "./pages/ProductSync";
import ProductMargin from "./pages/ProductMargin";
import ProductCategorySale from "./pages/ProductCategorySale";

import Qa from "./pages/Qa";
import QaList from "./pages/QaList";
import Document from "./pages/Document";
import DocumentList from "./pages/DocumentList";
import Client from "./pages/Client";
import ClientList from "./pages/ClientList";
import ResourcesAdmin from "./pages/Resources";
import ResourcesList from "./pages/ResourcesList";
import Team from "./pages/Team";
import TeamList from "./pages/TeamList";
import BlogList from "./pages/BlogList";
import BlogAdmin from "./pages/Blog";
import BlogDetailAdmin from "./pages/BlogDetailAdmin";
import CaseStudy from "./pages/CaseStudy";
import CaseStudyList from "./pages/CaseStudyList";
import ContactFormList from "./pages/ContactFormList";
import NotFound from "./components/Admin/NotFound";
import RoleList from "./pages/RoleList";
import Role from "./pages/Role";
import User from "./pages/User";
import UserList from "./pages/UserList";
import { locationPath } from "./constants/appRoutes";
import Testimonials from "./pages/Testimonials";
import TestimonialsList from "./pages/TestimonialsList";
import QaSorting from "./pages/QaSorting";
import { Helmet } from "react-helmet-async";
import NewsletterList from "./pages/NewsletterList";
import SliderList from "./pages/SliderList";
import Slider from "./pages/Slider";

import BannerList from "./pages/BannerList";
import Banner from "./pages/Banner";

const phpToken = localStorage.getItem("phpToken");
const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

if (phpToken) {
  let items = {
    validToken: phpToken,
    loggedUser,
  };
  store.dispatch(securityActions.setCurrentUser(items));
}

function App() {
  const loggedUserReducer = useSelector((state) => state.loggedUserReducer);

  const checkRoute = (component, action) => {
    const allowedActions = loggedUserReducer?.loggedUser?.loggedUser?.roles[0];
    if (allowedActions)
      if (!allowedActions.includes(action)) {
        return NotFound;
      } else {
        return component;
      }
  };

  const isAuthenticated = loggedUserReducer?.loggedUser?.validToken;

  return (
    <AuthContext.Provider
      value={{
        ...loggedUserReducer,
      }}
    >
      <Routes>
        {/* ADMIN RUTE */}
        {isAuthenticated ? (
          <Route
            exact
            path="/"
            element={
              <AdminRoute component={checkRoute(Dashboard, "MENU_DASHBOARD")} />
            }
          ></Route>
        ) : (
          // Ruta za neprijavljene korisnike
          <Route path="/" element={<Login />} />
        )}

        <Route
          exact
          path={locationPath.DASHBOARD}
          element={
            <AdminRoute component={checkRoute(Dashboard, "MENU_DASHBOARD")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.QA_ADD}
          element={<AdminRoute component={checkRoute(Qa, "MENU_QA")} />}
        ></Route>
        <Route
          exact
          path={locationPath.QA_EDIT}
          element={<AdminRoute component={checkRoute(Qa, "MENU_QA")} />}
        ></Route>
        <Route
          exact
          path={locationPath.QA_LIST}
          element={<AdminRoute component={checkRoute(QaList, "MENU_QA")} />}
        ></Route>
        <Route
          exact
          path={locationPath.QA_SORTING}
          element={<AdminRoute component={checkRoute(QaSorting, "MENU_QA")} />}
        ></Route>
        <Route
          exact
          path={locationPath.CLIENT_ADD}
          element={
            <AdminRoute component={checkRoute(Client, "MENU_CLIENTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CLIENT_EDIT}
          element={
            <AdminRoute component={checkRoute(Client, "MENU_CLIENTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CLIENT_LIST}
          element={
            <AdminRoute component={checkRoute(ClientList, "MENU_CLIENTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.TEAM_ADD}
          element={<AdminRoute component={checkRoute(Team, "MENU_TEAM")} />}
        ></Route>
        <Route
          exact
          path={locationPath.TEAM_EDIT}
          element={<AdminRoute component={checkRoute(Team, "MENU_TEAM")} />}
        ></Route>
        <Route
          exact
          path={locationPath.TEAM_LIST}
          element={<AdminRoute component={checkRoute(TeamList, "MENU_TEAM")} />}
        ></Route>
        <Route
          exact
          path={locationPath.PRODUCT_ADD}
          element={
            <AdminRoute component={checkRoute(Product, "MENU_PRODUCTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.PRODUCT_EDIT}
          element={
            <AdminRoute component={checkRoute(ProductEdit, "MENU_PRODUCTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.PRODUCT_LIST}
          element={
            <AdminRoute component={checkRoute(ProductList, "MENU_PRODUCTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.PRODUCT_CHECK}
          element={
            <AdminRoute component={checkRoute(ProductCheck, "MENU_PRODUCTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.PRODUCT_SYNC}
          element={
            <AdminRoute component={checkRoute(ProductSync, "MENU_PRODUCTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.PRODUCT_MARGIN}
          element={
            <AdminRoute
              component={checkRoute(ProductMargin, "MENU_PRODUCTS")}
            />
          }
          ProductMargin
        ></Route>
        <Route
          exact
          path={locationPath.PRODUCT_CATEGORY_SALE}
          element={
            <AdminRoute
              component={checkRoute(ProductCategorySale, "MENU_PRODUCTS")}
            />
          }
          ProductMargin
        ></Route>
        <Route
          exact
          path={locationPath.TESTIMONIALS_ADD}
          element={
            <AdminRoute
              component={checkRoute(Testimonials, "MENU_TESTIMONIALS")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.TESTIMONIALS_EDIT}
          element={
            <AdminRoute
              component={checkRoute(Testimonials, "MENU_TESTIMONIALS")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.TESTIMONIALS_LIST}
          element={
            <AdminRoute
              component={checkRoute(TestimonialsList, "MENU_TESTIMONIALS")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.USER_ADD}
          element={<AdminRoute component={checkRoute(User, "MENU_USERS")} />}
        ></Route>
        <Route
          exact
          path={locationPath.USER_EDIT}
          element={<AdminRoute component={checkRoute(User, "MENU_USERS")} />}
        ></Route>
        <Route
          exact
          path={locationPath.USER_LIST}
          element={
            <AdminRoute component={checkRoute(UserList, "MENU_USERS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.RESOURCES_ADD}
          element={
            <AdminRoute
              component={checkRoute(ResourcesAdmin, "MENU_RESOURCES")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.RESOURCES_EDIT}
          element={
            <AdminRoute
              component={checkRoute(ResourcesAdmin, "MENU_RESOURCES")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.RESOURCES_LIST}
          element={
            <AdminRoute
              component={checkRoute(ResourcesList, "MENU_RESOURCES")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.ROLE_ADD}
          element={<AdminRoute component={checkRoute(Role, "MENU_ROLES")} />}
        ></Route>
        <Route
          exact
          path={locationPath.ROLE_EDIT}
          element={<AdminRoute component={checkRoute(Role, "MENU_ROLES")} />}
        ></Route>
        <Route
          exact
          path={locationPath.ROLE_LIST}
          element={
            <AdminRoute component={checkRoute(RoleList, "MENU_ROLES")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.BLOG_ADD}
          element={
            <AdminRoute component={checkRoute(BlogAdmin, "MENU_BLOG")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.BLOG_EDIT}
          element={
            <AdminRoute component={checkRoute(BlogAdmin, "MENU_BLOG")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.BLOG_TRANSLATION_ADD}
          element={
            <AdminRoute component={checkRoute(BlogDetailAdmin, "MENU_BLOG")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.BLOG_TRANSLATION_EDIT}
          element={
            <AdminRoute component={checkRoute(BlogDetailAdmin, "MENU_BLOG")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.BLOG_LIST}
          element={<AdminRoute component={checkRoute(BlogList, "MENU_BLOG")} />}
        ></Route>
        <Route
          exact
          path={locationPath.DOCUMENT_ADD}
          element={
            <AdminRoute component={checkRoute(Document, "MENU_DOCUMENTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.DOCUMENT_EDIT}
          element={
            <AdminRoute component={checkRoute(Document, "MENU_DOCUMENTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.DOCUMENT_LIST}
          element={
            <AdminRoute
              component={checkRoute(DocumentList, "MENU_DOCUMENTS")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CASE_STUDY_ADD}
          element={
            <AdminRoute component={checkRoute(CaseStudy, "MENU_CASE_STUDY")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CASE_STUDY_EDIT}
          element={
            <AdminRoute component={checkRoute(CaseStudy, "MENU_CASE_STUDY")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CASE_STUDY_LIST}
          element={
            <AdminRoute
              component={checkRoute(CaseStudyList, "MENU_CASE_STUDY")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CONTACT_LIST}
          element={
            <AdminRoute
              component={checkRoute(ContactFormList, "MENU_CONTACT_FORM")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.SEO_LIST}
          element={
            <AdminRoute component={checkRoute(SeoPagesList, "MENU_SEO")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.SEO_FORM}
          element={
            <AdminRoute component={checkRoute(SeoPagesForm, "MENU_SEO")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.NEWSLETTER_LIST}
          element={
            <AdminRoute
              component={checkRoute(NewsletterList, "MENU_NEWSLETTER")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.SLIDER_ADD}
          element={<AdminRoute component={checkRoute(Slider, "MENU_SLIDER")} />}
        ></Route>
        <Route
          exact
          path={locationPath.SLIDER_EDIT}
          element={<AdminRoute component={checkRoute(Slider, "MENU_SLIDER")} />}
        ></Route>
        <Route
          exact
          path={locationPath.SLIDER_LIST}
          element={
            <AdminRoute component={checkRoute(SliderList, "MENU_SLIDER")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.BANNER_ADD}
          element={<AdminRoute component={checkRoute(Banner, "MENU_BANNER")} />}
        ></Route>
        <Route
          exact
          path={locationPath.BANNER_EDIT}
          element={<AdminRoute component={checkRoute(Banner, "MENU_BANNER")} />}
        ></Route>
        <Route
          exact
          path={locationPath.BANNER_LIST}
          element={
            <AdminRoute component={checkRoute(BannerList, "MENU_BANNER")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.ORDER_LIST}
          element={
            <AdminRoute component={checkRoute(OrderList, "MENU_ORDER")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.ORDER_DETAIL}
          element={
            <AdminRoute component={checkRoute(OrderDetail, "MENU_ORDER")} />
          }
        ></Route>
      </Routes>
      <ToastContainer />
    </AuthContext.Provider>
  );
}

export default App;
