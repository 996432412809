import React, { useEffect } from "react";
import { Fragment } from "react";
import Breadcrumb from "../../components/Admin/Reusable/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import ProductForm from "./Form/ProductForm";
import {
  fetchCreateNewProduct,
  fetchProductSizeList,
  clearEditProductCategory,
} from "../../actions/product";
import { useSelector } from "react-redux";

const Product = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dataProductSizeList } = useSelector((state) => state.productReducer);

  const initialDataState = {
    id: 0,
    code: "",
    price: "",
    id_category: 1,
    id_brend: 1,
    status: 1,
    cost_per_item: "",
    productLanguages: [],
    productSizesQty: [],
    percentage_sale: "",
    price: "",
    is_sale: false,
    is_popular: false,
    is_offer: false,
    is_action: false,
    is_recommended: false,
    price_sale: "",
    loyalty_percentage: "",
    loyalty_price: "",
  };

  useEffect(() => {
    dispatch(fetchProductSizeList(0));
    dispatch(clearEditProductCategory());
  }, [dispatch]);

  const handleSubmit = (event, data) => {
    event.preventDefault();

    dispatch(fetchCreateNewProduct(data, navigate));
  };

  return (
    <Fragment>
      <Breadcrumb title={`Kreiranje novog proizvoda`} type="product-forma" />
      <Container>
        <Row>
          <Col lg={12}>
            <ProductForm
              handleSubmitFunction={handleSubmit}
              initialDataState={initialDataState}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Product;
