import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/Admin/Reusable/Breadcrumb";
import { Col, Container, Row } from "react-bootstrap";
import ProductForm from "./Form/ProductForm";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchEditProduct,
  fetchProductPagesList,
  fetchProductSizeList,
  fetchProductCategoryEditList,
} from "../../actions/product";

const ProductEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { dataPagesDetail } = useSelector((state) => state.productReducer);
  const [initialStateData, setInitialStateData] = useState({
    code: "",
    price: "",
    id_category: 1,
    id_brend: 1,
    status: 1,
    cost_per_item: "",
    productLanguages: [],
    productSizesQty: [],
    percentage_sale: "",
    price: "",
    is_sale: false,
    is_popular: false,
    is_offer: false,
    is_action: false,
    is_recommended: false,
    price_sale: "",
    loyalty_percentage: "",
    loyalty_price: "",
  });

  useEffect(() => {
    dispatch(fetchProductPagesList(id));
    dispatch(fetchProductCategoryEditList(id));
    dispatch(fetchProductSizeList(0));
  }, [id, dispatch]);

  useEffect(() => {
    if (dataPagesDetail) {
      setInitialStateData({
        id: dataPagesDetail.id || "",
        code: dataPagesDetail.code || "",
        price: dataPagesDetail.price || "",
        id_category: dataPagesDetail.id_category || 1,
        id_brend: dataPagesDetail.id_brend || 1,
        status: dataPagesDetail.status || 1,
        cost_per_item: dataPagesDetail.cost_per_item || "",
        productLanguages: dataPagesDetail.productsLanguage || [],
        productSizesQty: dataPagesDetail.productSizeQty || [],
        percentage_sale: dataPagesDetail.percentage_sale || "",
        price_sale: dataPagesDetail.price_sale || "",
        is_sale: dataPagesDetail.is_sale == 1 ? true : false,
        is_popular: dataPagesDetail.is_popular == 1 ? true : false,
        is_offer: dataPagesDetail.is_offer == 1 ? true : false,
        is_action: dataPagesDetail.is_action == 1 ? true : false,
        is_recommended: dataPagesDetail.is_recommended == 1 ? true : false,
        loyalty_percentage: dataPagesDetail.loyalty_percentage || "",
        loyalty_price: dataPagesDetail.loyalty_price || "",
        image: dataPagesDetail.image || "",
        imagePath: dataPagesDetail.imagePath || "",
      });
    }
  }, [dataPagesDetail]);
  const handleSubmit = (event, data) => {
    event.preventDefault();
    dispatch(fetchEditProduct(data, navigate));
  };

  return (
    <Fragment>
      <Breadcrumb title={`Kreiranje novog proizvoda`} type="product-forma" />
      <Container>
        <Row>
          <Col lg={12}>
            <ProductForm
              handleSubmitFunction={handleSubmit}
              initialDataState={initialStateData}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ProductEdit;
