import {
  createNewUserPath,
  editUserPath,
  getListUserPath,
  removeUserPath,
  getDashboardInfo,
} from "../constants/apiRoutes";
import { userActions } from "../reducers/Admin/userActions";
import apiService from "../utils/configAxios";
import { toast } from "react-toastify";

export const fetchDashboardInfo = () => {
  return (dispatch) => {
    return apiService
      .get(getDashboardInfo())
      .then((response) => {
        dispatch(userActions.fetchDashboardInfo(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchCreateNewUser = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(createNewUserPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/user-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchDeleteUser = (pageId) => {
  return (dispatch) => {
    dispatch(userActions.actionStart());
    return apiService
      .delete(removeUserPath(pageId))
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        return apiService
          .get(getListUserPath(0))
          .then((response) => {
            dispatch(userActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchEditUser = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(editUserPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/user-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchUserPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(userActions.actionStart());
    return apiService
      .get(getListUserPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(userActions.fetchAllPagesList(response.data));
        else dispatch(userActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
