import React from "react";
import { NumericFormat } from "react-number-format";
import { isUndefined } from "lodash";
import InputField from "./InputField";

const AmountInputField = (props) => {
  const {
    label,
    prefix,
    suffix,
    name,
    className,
    value,
    tooltipText,
    onChange,
    onKeyDown,
    required,
    disabled,
    multiline = false,
    rows,
    size,
    defaultValue,
    inputProps,
    inputLabelProps = {},
    error,
    type = "input",
    maxLength = 99999999999999,
    minLength = 0,
    decimalScale = 2,
    isNumericString = true,
  } = props || {};

  const inputValue = value === null ? "" : value;
  // const errorMessage = typeof error !== "string" ? error?.[name] : error;
  // const hasError = !isUndefined(errorMessage);

  return (
    <NumericFormat
      customInput={InputField}
      isNumericString={isNumericString}
      rows={rows}
      InputLabelProps={inputLabelProps}
      InputProps={inputProps}
      tooltipText={tooltipText}
      multiline={multiline}
      className={className}
      name={name}
      value={inputValue}
      decimalScale={decimalScale}
      thousandSeparator={"."}
      decimalSeparator={","}
      fixedDecimalScale
      disabled={!!disabled}
      required={required}
      defaultValue={defaultValue}
      label={label}
      error={error}
      size={size}
      prefix={prefix}
      suffix={suffix}
      isAllowed={(values) => {
        if (!values.value) return true;
        const { floatValue } = values;
        return floatValue < maxLength;
      }}
      type={type}
      onValueChange={(values) => {
        // Uzimamo samo numerički deo vrednosti (bez sufiksa)
        onChange({
          target: { name: props.name, value: values.value },
        });
      }}
      onKeyDown={(event) => {
        onKeyDown?.(event);
      }}
    />
  );
};

export default AmountInputField;
