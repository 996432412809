import React from "react";
import { Fragment } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import CustomModal from "../../Common/Modal/CustomModal";
import { useModalToggle } from "../../../hooks/useModalToggle";
import Tooltip from "@mui/material/Tooltip";
import EditNoteIcon from "@mui/icons-material/EditNote";

const ButtonColumn = (props) => {
  const {
    submitActionModal,
    editLink,
    deleteItemId,
    deleteItemTitle,
    textToltip = "Izmena",
  } = props || {};

  const [showModal, toggleModal] = useModalToggle(false);

  const handleButtonRemoveClick = () => {
    toggleModal();
  };

  const modalCloseHandler = () => {
    toggleModal(false);
  };

  return (
    <Fragment>
      <Link to={editLink}>
        <Tooltip
          title={textToltip}
          disableInteractive
          placement="top"
          arrow={true}
        >
          <EditNoteIcon className="custom-btn-aggrid-customization" />
        </Tooltip>
      </Link>
      {deleteItemId && (
        <Tooltip
          title="Brisanje"
          disableInteractive
          placement="top"
          arrow={true}
        >
          <DeleteIcon
            className="edit-button-aggrid-delete-button custom-btn-aggrid-customization"
            onClick={handleButtonRemoveClick}
          />
        </Tooltip>
      )}
      {/* {addMoreButtonNews && (
        <Link to={`/adminManager/add-blog-detail/${value.data.anId}`}>
          <Tooltip title="Kreiranje detalja vesti na različitim jezicima">
            <Button
              className="edit-button-aggrid edit-button-aggrid-delete-button custom-btn-aggrid-customization"
              variant="contained"
              color="success"
              onClick={handleButtonRemoveClick}
              startIcon={<AddIcon />}
            ></Button>
          </Tooltip>
        </Link>
      )} */}
      {showModal && (
        <CustomModal
          show={showModal}
          hideModal={modalCloseHandler}
          modalTitle={`Potvrda brisanja`}
          actionSubmitModal={() => submitActionModal(deleteItemId)}
        >
          Da li ste sigurni da želite da obrišete <b>{deleteItemTitle}</b> ?
        </CustomModal>
      )}
    </Fragment>
  );
};

export default ButtonColumn;
