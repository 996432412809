import React, { useEffect, useRef } from "react";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import InputField from "../../../components/Admin/Reusable/InputField";
import AmountInputField from "../../../components/Admin/Reusable/AmountInputField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { isEmpty, isUndefined } from "lodash";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import {
  fetchProductCategoryList,
  fetchProductBrandList,
} from "../../../actions/product";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row } from "react-bootstrap";
import { fetchLanguageList } from "../../../actions/language";
import { basicFormValidationHandler } from "../../../utils";

const ProductForm = ({ handleSubmitFunction, initialDataState }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [data, setData] = useState(initialDataState);
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState("");

  const [selectedSizes, setSelectedSizes] = useState([]);
  // const isInitialMount = useRef(true);

  const {
    dataProductSizeList,
    dataProductCategoryList,
    dataProductBrandList,
    dataProductCategoryRelationList,
  } = useSelector((state) => state.productReducer);
  const { dataLanguageList } = useSelector((state) => state.languageReducer);

  useEffect(() => {
    //pozivanje jezika
    dispatch(fetchLanguageList());
    //pozivanje kategorija
    dispatch(fetchProductCategoryList());
    //pozivanje brendova
    dispatch(fetchProductBrandList());
  }, [dispatch]);

  useEffect(() => {
    // if (isInitialMount.current) {
    setData(initialDataState); // Postavi initialDataState samo prvi put
    // isInitialMount.current = false;
    // }
  }, [initialDataState]);

  // Funkcija za ažuriranje naziva i opisa za svaki jezik
  const handleLanguageInputChange = (id, field, value) => {
    setData((prevData) => {
      const updatedLanguages = prevData.productLanguages.map((language) => {
        if (language.id === id) {
          return {
            ...language,
            [field]: value,
          };
        }
        return language;
      });

      // Ako nije pronađen jezik sa tim `id`, dodajte novi objekat u niz
      if (!updatedLanguages.some((language) => language.id === id)) {
        updatedLanguages.push({ id, [field]: value });
      }

      return {
        ...prevData,
        productLanguages: updatedLanguages,
      };
    });
  };

  // Funkcija za rukovanje izborom datoteke
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSizeInMB = file.size / 1048576; // Konvertujemo veličinu u MB
      const validExtensions = ["image/jpeg", "image/jpg", "image/png"]; // Dozvoljeni MIME tipovi

      // Proveravamo veličinu i tip fajla
      if (fileSizeInMB > 5) {
        toast.error(
          "Odabrani dokument je prevelik. Maksimalna veličina je 5MB.",
          { theme: "colored" }
        );
        resetFileInput(event);
        return;
      }

      if (!validExtensions.includes(file.type)) {
        toast.error("Dozvoljeni formati su: JPG, JPEG, PNG.", {
          theme: "colored",
        });
        resetFileInput(event);
        return;
      }

      setSelectedFile(file); // Postavljamo selektovanu sliku
    }
  };

  // Funkcija za resetovanje inputa
  const resetFileInput = (event) => {
    setData((prevUser) => ({
      ...prevUser,
      acDocument: null,
    }));
    setSelectedFile(null);
    event.target.value = null; // Resetujemo input
  };

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event?.target || {};

    // Ako je ček-boks, postavite vrednost na "1" ako je čekiran, inače "0"
    const inputValue = type === "checkbox" ? checked : value;

    //ako se procenat unosi racunam trenutnu cenu odmah
    if (name === "percentage_sale") {
      const price = data.price;
      const salePrice = (price * inputValue) / 100;
      const totalSalePrice = price - salePrice;

      if (value == "") {
        setData((prevUser) => ({
          ...prevUser,
          price_sale: "",
        }));
      } else {
        setData((prevUser) => ({
          ...prevUser,
          price_sale: totalSalePrice,
        }));
      }
    }

    //promena loyalty procenta
    if (name === "loyalty_percentage") {
      const price = data.price;
      const loyaltyPrice = (price * inputValue) / 100;
      const totalLoyaltyPrice = price - loyaltyPrice;

      if (value == "") {
        setData((prevUser) => ({
          ...prevUser,
          loyalty_price: "",
        }));
      } else {
        setData((prevUser) => ({
          ...prevUser,
          loyalty_price: totalLoyaltyPrice,
        }));
      }
    }

    //kada se menja cena, potrebno je setovati loyaty cenu odmah
    if (name === "price") {
      const price = value;

      if (data.loyalty_percentage > 0 || data.loyalty_percentage != "") {
        const loyaltyPrice = (price * data.loyalty_percentage) / 100;
        const totalLoyaltyPrice = price - loyaltyPrice;

        setData((prevUser) => ({
          ...prevUser,
          loyalty_price: totalLoyaltyPrice,
        }));
      }

      if (data.percentage_sale > 0 || data.percentage_sale != "") {
        const salePrice = (price * data.percentage_sale) / 100;
        const totalSalePrice = price - salePrice;

        setData((prevUser) => ({
          ...prevUser,
          price_sale: totalSalePrice,
        }));
      }
    }

    setData((prevUser) => ({
      ...prevUser,
      [name]: inputValue,
    }));
  };

  const clientValidationHandler = () => {
    const { code, productLanguages } = data;
    const errorsObject = basicFormValidationHandler({
      code,
    });

    // Proveri ako je productLanguages prazan
    if (productLanguages.length === 0) {
      errorsObject["name-1"] = "obavezno polje";
      errorsObject["name-2"] = "obavezno polje";
    } else {
      // Validacija za jezike
      productLanguages.forEach((language) => {
        if (!language.name || language.name.trim() === "") {
          errorsObject[
            `name-${language.id}`
          ] = `Naziv za jezik ${language.id} je obavezan.`;
        }
      });
    }

    setErrors(errorsObject);

    if (!isEmpty(errorsObject)) {
      toast.error("Morate popuniti sva obavezna polja", { theme: "colored" });
    }

    return !isEmpty(errorsObject); // Ako ima grešaka, vraća `true`
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (clientValidationHandler()) {
      return;
    }

    // Provera da li je slika odabrana
    if (!selectedFile && !data.id) {
      toast.error("Morate odabrati sliku", { theme: "colored" });
      return;
    }

    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      // Provera za ugnježdene objekte (npr. productLanguages) - koristi JSON string
      if (typeof value === "object" && value !== null) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    }
    selectedFile && formData.append("image", selectedFile);

    formData.append("productCategory", JSON.stringify(selectedSizes));

    handleSubmitFunction(event, formData);
  };

  const handleCheckboxChange = (event, item) => {
    const { checked } = event.target;
    console.log(item, "item");
    if (checked) {
      // Dodajte ceo objekat u listu kada je checkbox označen
      setSelectedSizes((prevSelected) => [...prevSelected, item]);
    } else {
      // Uklonite ceo objekat iz liste kada je checkbox poništen
      setSelectedSizes((prevSelected) =>
        prevSelected.filter((selectedItem) => selectedItem.id !== item.id)
      );
    }
  };

  useEffect(() => {
    if (dataProductCategoryRelationList?.length > 0) {
      // Mapa da transformišemo podatke u željeni format
      const formattedSizes = dataProductCategoryRelationList.map((item) => ({
        id: parseInt(item.id_category_id, 10), // id iz baze
        name: item.name, // name iz baze
        id_category: item.id_category, // id_category iz baze
        price: item.price, // id_category iz baze
        status: item.status || "1", // status, ako nije definisan postavljamo na "1"
      }));

      setSelectedSizes(formattedSizes); // Postavljamo selektovane veličine u željeni format
    } else {
      setSelectedSizes([]);
    }
  }, [dataProductCategoryRelationList]);

  const handlePriceChange = (id, price) => {
    setSelectedSizes(
      selectedSizes.map((selectedItem) =>
        selectedItem.id === id ? { ...selectedItem, price } : selectedItem
      )
    );
  };

  console.log(selectedSizes, "selectedSizes");

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Row>
        <Col xl={9} lg={8} md={6}>
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Header>
                  <h6 className="mb-0">Osnovni podaci</h6>
                </Card.Header>
                <Card.Body>
                  <div className="product-holder-admin-left-side-text">
                    <Row>
                      <Col lg={3} md={12} xs={12} className="mb-3">
                        <InputField
                          label="Šifra proizvoda"
                          name="code"
                          error={errors}
                          errorsHandler={setErrors}
                          value={data.code}
                          onChange={handleInputChange}
                          fullWidth
                          size="small"
                          required
                          disabled={data.id && true}
                        />
                      </Col>
                      <Col lg={3} md={12} xs={12} className="mb-3">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Kategorija proizvoda
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={data.id_category}
                            label="Kategorija proizvoda"
                            name="id_category"
                            onChange={handleInputChange}
                            size="small"
                          >
                            {dataProductCategoryList?.map((item, key) => (
                              <MenuItem key={key} value={item.id_category}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Col>
                      {data.id_category > 1 && (
                        <Col lg={3} md={12} xs={12} className="mb-3">
                          <AmountInputField
                            suffix={" RSD"}
                            label="Cena"
                            name="price"
                            error={errors}
                            errorsHandler={setErrors}
                            value={data.price}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                            className="mb-1"
                          />
                        </Col>
                      )}

                      <Col lg={3} md={12} xs={12} className="mb-3">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Status proizvoda
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={data.status}
                            label="Status proizvoda"
                            name="status"
                            onChange={handleInputChange}
                            size="small"
                          >
                            <MenuItem value="1">Aktivan</MenuItem>
                            <MenuItem value="0">Neaktivan</MenuItem>
                          </Select>
                        </FormControl>
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={12}>
              <Card>
                <Card.Header>
                  <h6 className="mb-0">Naziv proizvoda</h6>
                </Card.Header>
                <Card.Body>
                  <div className="product-holder-main-admin-name">
                    <Row>
                      {dataLanguageList.length > 0 ? (
                        <Fragment>
                          {dataLanguageList?.map((item) => (
                            <Col lg={6} key={item.anId}>
                              <InputField
                                label={`Naziv ` + item.acName}
                                name={`name-${item.anId}`}
                                value={
                                  data.productLanguages.find(
                                    (language) => language.id === item.anId
                                  )?.name || ""
                                }
                                onChange={(e) =>
                                  handleLanguageInputChange(
                                    item.anId,
                                    "name",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                required
                                size="small"
                                className="mb-4"
                                multiline
                                error={errors}
                                errorsHandler={setErrors}
                              />
                              <InputField
                                label={`Kratak opis ` + item.acName}
                                name={`description-${item.anId}`}
                                multiline
                                rows={4}
                                value={
                                  data.productLanguages.find(
                                    (language) => language.id === item.anId
                                  )?.description || ""
                                }
                                onChange={(e) =>
                                  handleLanguageInputChange(
                                    item.anId,
                                    "description",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                size="small"
                                className="mb-4"
                              />
                              <InputField
                                label={`Opis ` + item.acName}
                                name={`long_description-${item.anId}`}
                                multiline
                                rows={16}
                                value={
                                  data.productLanguages.find(
                                    (language) => language.id === item.anId
                                  )?.long_description || ""
                                }
                                onChange={(e) =>
                                  handleLanguageInputChange(
                                    item.anId,
                                    "long_description",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                size="small"
                                className="mb-4"
                              />
                            </Col>
                          ))}
                        </Fragment>
                      ) : (
                        <Col lg={12}>Nema podataka.</Col>
                      )}
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={12}>
              <Card>
                <Card.Body>
                  <div className="float-end ">
                    {id ? (
                      <Button
                        type="submit"
                        variant="contained"
                        color="success"
                        startIcon={<CheckIcon />}
                      >
                        Potvrda izmene
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        color="success"
                        startIcon={<AddIcon />}
                      >
                        Kreiraj
                      </Button>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col className="right-holder-admin-forms" xl={3} lg={4} md={6}>
          <Row>
            {data.id_category == 1 && (
              <Fragment>
                <Col lg={12}>
                  <Card>
                    <Card.Header>Veličina pakovanja</Card.Header>
                    <Card.Body>
                      <Row>
                        <div className="qty-list-product-holder">
                          {dataProductSizeList?.filter(
                            (item) => item.id_category == 1
                          ).length > 0 &&
                            dataProductSizeList
                              ?.filter((item) => item.id_category == 1)
                              .map((item, key) => (
                                <Col md={12}>
                                  <div className="holder-checkbox-size-price d-flex flex-row flex-justify-between gap-10 mb-2">
                                    <div className="holder-checkbox-size">
                                      <FormControlLabel
                                        key={key} // Dodajte key prop za optimizaciju i da bi React mogao da prati elemente
                                        control={
                                          <Checkbox
                                            name="product_size"
                                            value={item.id} // Koristimo ID za identifikaciju, ali čuvamo ceo objekat
                                            checked={selectedSizes.some(
                                              (selectedItem) =>
                                                selectedItem.id === item.id
                                            )} // Proveravamo da li je objekat u selectedSizes
                                            onChange={(e) =>
                                              handleCheckboxChange(e, item)
                                            }
                                          />
                                        }
                                        label={item.name}
                                      />
                                    </div>
                                    <div className="holder-checkbox-price">
                                      {selectedSizes.some(
                                        (selectedItem) =>
                                          selectedItem.id == item.id
                                      ) && (
                                        <AmountInputField
                                          suffix={" RSD"}
                                          label={`Cena`}
                                          required
                                          name={`price_${item.id}`} // Jedinstveno ime
                                          error={errors}
                                          errorsHandler={setErrors}
                                          value={
                                            selectedSizes.find(
                                              (selectedItem) =>
                                                selectedItem.id == item.id
                                            )?.price || ""
                                          }
                                          onChange={(e) =>
                                            handlePriceChange(
                                              item.id,
                                              e.target.value
                                            )
                                          }
                                          fullWidth
                                          size="small"
                                          className="mb-1"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              ))}
                        </div>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={12}>
                  <Card>
                    <Card.Header>Način mlevenja</Card.Header>
                    <Card.Body>
                      <Row>
                        <div className="qty-list-product-holder">
                          {dataProductSizeList?.filter(
                            (item) => item.id_category == 2
                          ).length > 0 &&
                            dataProductSizeList
                              ?.filter((item) => item.id_category == 2)
                              .map((item, key) => (
                                <Col md={12}>
                                  <FormControlLabel
                                    key={key} // Dodajte key prop za optimizaciju i da bi React mogao da prati elemente
                                    control={
                                      <Checkbox
                                        name="product_size"
                                        value={item.id} // Koristimo ID za identifikaciju, ali čuvamo ceo objekat
                                        checked={selectedSizes.some(
                                          (selectedItem) =>
                                            selectedItem.id === item.id
                                        )} // Proveravamo da li je objekat u selectedSizes
                                        onChange={(e) =>
                                          handleCheckboxChange(e, item)
                                        }
                                      />
                                    }
                                    label={item.name}
                                  />
                                </Col>
                              ))}
                        </div>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Fragment>
            )}
            <Col lg={12}>
              <Card>
                <Card.Header>Glavna slika proizvoda</Card.Header>
                <Card.Body>
                  <div className="image-product-holder-admin">
                    <Input
                      required
                      type="file"
                      error={!isUndefined(errors["selectedFile"])}
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.files.length === 0) {
                          setSelectedFile(null);
                        } else {
                          handleFileSelect(e);
                        }
                      }}
                      id="file-input"
                      name="acFileName"
                      accept=".jpg, .jpeg, .png"
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="file-input"
                      style={{ position: "relative" }}
                    >
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                      >
                        Priložite fajl
                      </Button>
                    </label>
                    {selectedFile && selectedFile.type.startsWith("image/") && (
                      <div className="upload-table-preview-form mt-2">
                        <div className="upload-table-preview-form-holder-container">
                          <div className="upload-table-preview-image">
                            <img
                              src={URL.createObjectURL(selectedFile)} // Kreiramo URL za sliku
                              alt="Odabrana slika"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                            />
                          </div>
                          <Button
                            className="mt-2"
                            variant="contained"
                            color="error"
                            type="button"
                            size="small"
                            startIcon={<DeleteIcon />}
                            onClick={() => {
                              setSelectedFile(null);
                              document.getElementById("file-input").value = ""; // Resetovanje input polja za datoteku
                            }}
                          >
                            Obriši
                          </Button>
                        </div>
                      </div>
                    )}

                    {!selectedFile && initialDataState.imagePath && (
                      <div className="upload-table-preview-form mt-2">
                        <div className="upload-table-preview-form-holder-container">
                          <div className="upload-table-preview-image">
                            <img
                              src={initialDataState.imagePath} // Direktna apsolutna putanja
                              alt="Postojeća slika"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </form>
  );
};

export default ProductForm;
