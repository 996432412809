import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataPagesList: [],
  dataProductSizeList: [],
  dataProductCategoryList: [],
  dataProductBrandList: [],
  dataPagesDetail: [],
  dataPagesCheckList: [],
  dataPagesCheckDetail: [],
  dataCategoryGroup: [],
  dataSyncList: [],
  dataProductCategoryRelationList: [],
  loading: false,
  error: undefined,
  dataOrderList: [],
  dataOrderItemsList: [],
  dataOrderDetail: [],
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined;
      state.loading = false;
    },
    actionStart(state) {
      state.loading = true;
      state.error = undefined;
    },
    fetchAllPagesList(state, action) {
      state.dataPagesList = action.payload;
      state.dataPagesDetail = [];
      state.error = undefined;
      state.loading = false;
    },
    fetchAllPagesDetail(state, action) {
      state.dataPagesDetail = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchAllPagesCheckList(state, action) {
      state.dataPagesCheckList = action.payload;
      state.dataPagesCheckDetail = [];
      state.error = undefined;
      state.loading = false;
    },
    fetchAllPagesCheckDetail(state, action) {
      state.dataPagesCheckDetail = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchAllCategoryGroupList(state, action) {
      state.dataCategoryGroup = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchAllSyncList(state, action) {
      state.dataSyncList = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchAllProductCategoriesList(state, action) {
      state.error = undefined;
      state.loading = false;
    },
    fetchProductSizeList(state, action) {
      state.dataProductSizeList = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchAllProductCategoryRelationList(state, action) {
      state.dataProductCategoryRelationList = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchProductCategoryList(state, action) {
      state.dataProductCategoryList = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchAllOrderList(state, action) {
      state.dataOrderList = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchAllOrderItemsList(state, action) {
      state.dataOrderItemsList = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchAllOrderDetail(state, action) {
      state.dataOrderDetail = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchProductBrandList(state, action) {
      state.dataProductBrandList = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    clearEditProductCategory(state, action) {
      state.dataProductCategoryRelationList = [];
      state.error = undefined;
      state.loading = false;
    },
  },
});

export const productActions = productSlice.actions;

export default productSlice.reducer;
