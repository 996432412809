import {
  createNewProductPath,
  editProductPath,
  getListProductPath,
  getListProductCheckPath,
  getLoadProductsRefresh,
  getLoadProductsCheckRefresh,
  removeProductPath,
  getCategoryGroupProduct,
  getSyncListItems,
  createNewProductsSale,
  updateMarginGotovinskoFunction,
  getProductSize,
  getProductCategory,
  getProductBrand,
  getListProductCategoryRelation,
  getListOrderPath,
  getListOrderItemsPath,
  changeOrderStatus,
  changeDeliveryStatus,
} from "../constants/apiRoutes";
import { productActions } from "../reducers/Admin/productActions";
import apiService from "../utils/configAxios";
import { toast } from "react-toastify";

export const fetchCreateNewProduct = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(createNewProductPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/product-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchDeleteProduct = (pageId) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .delete(removeProductPath(pageId))
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        return apiService
          .get(getListProductPath(0))
          .then((response) => {
            dispatch(productActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            dispatch(productActions.clearError());
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        dispatch(productActions.clearError());
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchEditProduct = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(editProductPath(), dataValues)
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });

        navigate("/adminManager/product-list");
        // return apiService
        //   .get(getListProductPath(pageId))
        //   .then((response) => {
        //     if (pageId === 0)
        //       dispatch(productActions.fetchAllPagesList(response.data));
        //     else dispatch(productActions.fetchAllPagesDetail(response.data));
        //   })
        //   .catch((err) => {
        //     let errorMessage = err.response ? err.response.data.message : err;
        //     if (!err.response) {
        //       toast.error(
        //         "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
        //         { theme: "colored" }
        //       );
        //     }
        //     toast.error(errorMessage, { theme: "colored" });
        //   });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchProductPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getListProductPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(productActions.fetchAllPagesList(response.data));
        else dispatch(productActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchOrderPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getListOrderPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(productActions.fetchAllOrderList(response.data));
        else dispatch(productActions.fetchAllOrderDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchOrderItemsPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getListOrderItemsPath(pageId))
      .then((response) => {
        dispatch(productActions.fetchAllOrderItemsList(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchChangeOrderStatus = (dataValues) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .post(changeOrderStatus(), dataValues)
      .then((response) => {
        toast.success(response.data.message, {
          theme: "colored",
        });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchChangeDeliveryStatus = (dataValues) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .post(changeDeliveryStatus(), dataValues)
      .then((response) => {
        toast.success(response.data.message, {
          theme: "colored",
        });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchProductCategoryEditList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getListProductCategoryRelation(pageId))
      .then((response) => {
        dispatch(
          productActions.fetchAllProductCategoryRelationList(response.data)
        );
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchProductCheckPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getListProductCheckPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(productActions.fetchAllPagesCheckList(response.data));
        else dispatch(productActions.fetchAllPagesCheckDetail(response.data));
      })
      .catch((err) => {
        dispatch(productActions.clearError());
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchLoadProducts = (pageId = 0) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getLoadProductsRefresh())
      .then((response) => {
        return apiService
          .get(getListProductPath(pageId))
          .then((response) => {
            toast.success("Podaci su uspešno osveženi", {
              theme: "colored",
            });
            if (pageId === 0)
              dispatch(productActions.fetchAllPagesList(response.data));
            else dispatch(productActions.fetchAllPagesDetail(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchLoadProductsCheck = (pageId = 0) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getLoadProductsCheckRefresh())
      .then((response) => {
        return apiService
          .get(getListProductCheckPath(pageId))
          .then((response) => {
            if (pageId === 0)
              dispatch(productActions.fetchAllPagesCheckList(response.data));
            else
              dispatch(productActions.fetchAllPagesCheckDetail(response.data));
          })
          .catch((err) => {
            dispatch(productActions.clearError());
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchCategoryGroupList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getCategoryGroupProduct())
      .then((response) => {
        dispatch(productActions.fetchAllCategoryGroupList(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchSyncList = () => {
  return (dispatch) => {
    dispatch(productActions.actionStart());
    return apiService
      .get(getSyncListItems())
      .then((response) => {
        dispatch(productActions.fetchAllSyncList(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchCreateCategoryGroupMargin = (dataValues) => {
  return (dispatch) => {
    return apiService
      .post(createNewProductsSale(), dataValues)
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const updateMarginGotovinskoValue = (dataValues) => {
  return (dispatch) => {
    return apiService
      .post(updateMarginGotovinskoFunction(), dataValues)
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchProductSizeList = (id_category) => {
  return (dispatch) => {
    return apiService
      .get(getProductSize(id_category))
      .then((response) => {
        dispatch(productActions.fetchProductSizeList(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const clearEditProductCategory = () => {
  return (dispatch) => {
    dispatch(productActions.clearEditProductCategory());
  };
};

export const fetchProductCategoryList = () => {
  return (dispatch) => {
    return apiService
      .get(getProductCategory())
      .then((response) => {
        dispatch(productActions.fetchProductCategoryList(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchProductBrandList = () => {
  return (dispatch) => {
    return apiService
      .get(getProductBrand())
      .then((response) => {
        dispatch(productActions.fetchProductBrandList(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
