import {
  fetchDeleteProduct,
  fetchProductPagesList,
} from "../../actions/product";
import { useDispatch, useSelector } from "react-redux";

import AggridTable from "../../components/Admin/Reusable/AggridTable";
import Breadcrumb from "../../components/Admin/Reusable/Breadcrumb";
import ButtonColumn from "../../components/Admin/Reusable/ButtonColumn";
import { Fragment } from "react";
import LoadingIndicator from "../../components/Admin/Reusable/LoadingIndicator";
import React from "react";
import { statusRenderActivate } from "../../utils/cellRenderTable";
import { useEffect } from "react";

const ProductList = () => {
  const dispatch = useDispatch();

  const { dataPagesList, loading } = useSelector(
    (state) => state.productReducer
  );

  useEffect(() => {
    dispatch(fetchProductPagesList());
  }, [dispatch]);

  //akcija brisanja iz liste
  const handleSubmitModal = (pageId) => {
    dispatch(fetchDeleteProduct(pageId));
  };

  const imageRenderer = (params) =>
    params.data.imagePath && (
      <img
        src={`${params.data.imagePath}`}
        className="img-fluid image-preview-block-table"
        alt="No image"
      />
    );

  const customCellRenderer = (params) => (
    <ButtonColumn
      editLink={`/adminManager/product-edit/` + params.data.id}
      deleteItemId={params.data.id}
      deleteItemTitle={params.data.code}
      value={params}
      submitActionModal={handleSubmitModal}
    />
  );

  //settings za tabelu
  const columnDefs = [
    // { headerName: "ID", field: "id", filter: true, width: 100 },
    {
      headerName: "Slika",
      field: "imagePath",
      width: 100,
      cellRenderer: imageRenderer,
    },
    {
      headerName: "Šifra",
      field: "code",
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Naziv proizvoda",
      field: "product_name",
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 300,
    },
    {
      headerName: "Kategorija",
      field: "category_name",
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Cena",
      field: "price",
      filter: "agTextColumnFilter",
      width: 200,
    },
    {
      headerName: "Status",
      field: "status",
      filter: "agTextColumnFilter",
      cellRenderer: statusRenderActivate,
      width: 150,
      filterParams: {
        defaultOption: "startsWith",
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Datum kreiranja",
      field: "date_created",
      filter: "agDateColumnFilter",
      width: 200,
    },
    {
      headerName: "Datum poslednje izmene",
      field: "date_modification",
      filter: "agDateColumnFilter",
      width: 220,
    },
    {
      headerName: "Panel",
      field: "custom",
      cellRenderer: customCellRenderer,
      pinned: "right",
      width: 120,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb title="Pregled proizvoda" type="product-lista" />

      <AggridTable
        rowData={dataPagesList}
        columnDefs={columnDefs}
        paginationPageSize={20}
      />
      <LoadingIndicator loading={loading} />
    </Fragment>
  );
};

export default ProductList;
