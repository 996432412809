import { Chip } from "@mui/material";

export const statusRender = (params) => {
  switch (params.data.acStatusName) {
    case "Neaktivan":
      return <Chip label="Neaktivan" color="error" variant="outlined" />;
    case "Aktivan":
      return <Chip label="Aktivan" color="success" variant="outlined" />;
    default:
      break;
  }
};

export const statusRenderActivate = (params) => {
  switch (params.data.status) {
    case "Neaktivan":
      return <Chip label="Neaktivan" color="error" variant="outlined" />;
    case "Aktivan":
      return <Chip label="Aktivan" color="success" variant="outlined" />;
    default:
      break;
  }
};

export const statusRenderStatus = (params) => {
  switch (params.data.payment_status_name) {
    case "U obradi":
      return (
        <Chip
          label="U obradi"
          size="small"
          sx={{
            backgroundColor: "#FFE0B2", // Nova pastelna narandžasta
            color: "#EF6C00", // Tamnija narandžasta za tekst
            borderRadius: "4px",
          }}
        />
      );
    case "Poručeno":
      return (
        <Chip
          label="Poručeno"
          size="small"
          sx={{
            backgroundColor: "#E3F2FD", // Blaga plava
            color: "#1565C0", // Tamnija plava za tekst
            borderRadius: "4px",
          }}
        />
      );
    case "Plaćeno":
      return (
        <Chip
          label="Plaćeno"
          size="small"
          sx={{
            backgroundColor: "#E8F5E9", // Blaga zelena
            color: "#2E7D32", // Tamnija zelena za tekst
            borderRadius: "4px",
          }}
        />
      );
    case "Nije plaćeno":
      return (
        <Chip
          label="Nije plaćeno"
          size="small"
          sx={{
            backgroundColor: "#F3E5F5", // Blaga ljubičasta
            color: "#6A1B9A", // Tamnija ljubičasta za tekst
            borderRadius: "4px",
          }}
        />
      );
    case "Odbijeno":
      return (
        <Chip
          label="Odbijeno"
          size="small"
          sx={{
            backgroundColor: "#FFEBEE", // Blaga crvena
            color: "#C62828", // Tamnija crvena za tekst
            borderRadius: "4px",
          }}
        />
      );
    case "Nepoznat status":
      return (
        <Chip
          label="Nepoznat status"
          size="small"
          sx={{
            backgroundColor: "#ECEFF1", // Blaga siva
            color: "#37474F", // Tamnija siva za tekst
            borderRadius: "4px",
          }}
        />
      );
    default:
      break;
  }
};

export const statusRenderStatusDelivery = (params) => {
  switch (params.data.is_delivery_name) {
    case "Nije isporučeno":
      return (
        <Chip
          label="Nije isporučeno"
          size="small"
          sx={{
            backgroundColor: "#FFEBEE", // Blaga crvena
            color: "#C62828", // Tamnija crvena za tekst
            borderRadius: "4px",
          }}
        />
      );
    case "Isporučeno":
      return (
        <Chip
          label="Isporučeno"
          size="small"
          sx={{
            backgroundColor: "#E8F5E9", // Blaga zelena
            color: "#2E7D32", // Tamnija zelena za tekst
            borderRadius: "4px",
          }}
        />
      );
    default:
      break;
  }
};

export const primaryRender = (params) => {
  switch (params.data.isPrimary) {
    case false:
      return <Chip label="Ne" color="error" variant="outlined" />;
    case true:
      return <Chip label="Da" color="success" variant="outlined" />;
    default:
      break;
  }
};
