import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Breadcrumb from "../../components/Admin/Reusable/Breadcrumb";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import {
  fetchOrderPagesList,
  fetchOrderItemsPagesList,
  fetchChangeOrderStatus,
  fetchChangeDeliveryStatus,
} from "../../actions/product";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { baseUrl, formattedPrice } from "../../utils";

const OrderDetail = () => {
  const [data, setData] = useState({
    status: 1,
    is_delivery: 0,
  });
  const dispatch = useDispatch();
  const { id } = useParams();

  const { dataOrderDetail, dataOrderItemsList, loading } = useSelector(
    (state) => state.productReducer
  );

  useEffect(() => {
    dispatch(fetchOrderPagesList(id));
    dispatch(fetchOrderItemsPagesList(id));
  }, [dispatch]);

  const handleInputChange = (event) => {
    const { name, value } = event?.target || {};

    setData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));

    const dataValues = {
      status: value,
      id_order: id,
    };

    //potrebno je da pozovem back i promenim i u bazi vrednost statusa
    dispatch(fetchChangeOrderStatus(dataValues));
  };

  const handleInputChangeDelivery = (event) => {
    const { name, value } = event?.target || {};

    setData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));

    const dataValues = {
      is_delivery: value,
      id_order: id,
    };

    //potrebno je da pozovem back i promenim i u bazi vrednost statusa
    dispatch(fetchChangeDeliveryStatus(dataValues));
  };

  useEffect(() => {
    setData({
      status: dataOrderDetail[0]?.payment_status,
      is_delivery: dataOrderDetail[0]?.is_delivery,
    });
  }, [dataOrderDetail]);

  return (
    <Fragment>
      <Breadcrumb title={`Pregled porudžbine`} type="order-detail" />
      <Container>
        <Row>
          <Col xl={9} lg={8} md={12}>
            <Card>
              <Card.Header>
                <Card.Title
                  as="h5"
                  className="title-card-information-customer-main"
                >
                  Porudžbina <b>#{dataOrderDetail[0]?.order_code}</b>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="table-responsive">
                  <table className="table table-hover table-order-detail-items-list">
                    <thead>
                      <tr>
                        <th scope="col" width={100}>
                          Šfira
                        </th>
                        <th scope="col">Slika</th>
                        <th scope="col">Naziv</th>
                        <th scope="col" className="text-end">
                          Količina
                        </th>
                        <th scope="col" width={150} className="text-end">
                          Cena
                        </th>
                        <th scope="col" className="text-end" width={150}>
                          Ukupno
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataOrderItemsList?.map((item, key) => (
                        <tr>
                          <td>{item?.product_code}</td>
                          <td>
                            <img
                              src={baseUrl + "images/products/" + item?.image}
                              alt="Fuka"
                              width={80}
                            />
                          </td>
                          <td>{item?.product_name}</td>
                          <td className="text-end">{item?.qty}</td>
                          <td className="text-end">
                            {formattedPrice(item?.price)} RSD
                          </td>
                          <td className="text-end">
                            {formattedPrice(item?.total_price)} RSD
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={3} lg={4} md={12}>
            <Row>
              <Col xl={12} lg={12} md={12}>
                <Card>
                  <Card.Header>
                    <Card.Title
                      as="h5"
                      className="title-card-information-customer"
                    >
                      Status porudžbine
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div className="detail-order-information-address">
                      <div className="detail-order-information-customer-name">
                        <div className="detail-order-information-customr-status">
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Status
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={data.status}
                              label="Status"
                              name="status"
                              size="small"
                              disabled={data.status == 0 || data.status == 2}
                              onChange={handleInputChange}
                            >
                              <MenuItem value={0}>U obradi</MenuItem>
                              <MenuItem value={1}>Poručeno</MenuItem>
                              <MenuItem value={2}>Plaćeno</MenuItem>
                              <MenuItem value={3}>Nije plaćeno</MenuItem>
                              <MenuItem value={4}>Odbijeno</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={12} lg={12} md={12}>
                <Card>
                  <Card.Header>
                    <Card.Title
                      as="h5"
                      className="title-card-information-customer"
                    >
                      Detalji kupovine
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div className="detail-order-information-address">
                      <div className="detail-order-information-customer-name detail-order-information-customer-item">
                        <h5>Transakcija</h5>
                        <h4>#{dataOrderDetail[0]?.order_code}</h4>
                      </div>
                    </div>
                    <div className="detail-order-information-address">
                      <div className="detail-order-information-customer-name detail-order-information-customer-item">
                        <h5>Ukupan iznos</h5>
                        <h4>
                          {formattedPrice(dataOrderDetail[0]?.total_price_sum)}
                          RSD
                        </h4>
                      </div>
                    </div>
                    <div className="detail-order-information-address">
                      <div className="detail-order-information-customer-name detail-order-information-customer-item">
                        <h5>Datum kreiranja</h5>
                        <h4>{dataOrderDetail[0]?.date_created}</h4>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={12} lg={12} md={12}>
                <Card>
                  <Card.Header>
                    <Card.Title
                      className="title-card-information-customer"
                      as="h5"
                    >
                      Podaci o kupcu
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div className="detail-order-information-customer">
                      <div className="detail-order-information-customer-name detail-order-information-customer-item">
                        <h5>Ime i prezime:</h5>
                        <h4>
                          {dataOrderDetail[0]?.first_name}{" "}
                          {dataOrderDetail[0]?.last_name}
                        </h4>
                      </div>
                      <div className="detail-order-information-customer-email detail-order-information-customer-item">
                        <h5>Email:</h5>
                        <h4>{dataOrderDetail[0]?.email}</h4>
                      </div>
                      <div className="detail-order-information-customer-phone detail-order-information-customer-item">
                        <h5>Telefon:</h5>
                        <h4>{dataOrderDetail[0]?.phone}</h4>
                      </div>
                      <div className="detail-order-information-customer-phone detail-order-information-customer-item">
                        <h5>Napomena:</h5>
                        <h4>{dataOrderDetail[0]?.note}</h4>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={12} lg={12} md={12}>
                <Card>
                  <Card.Header>
                    <Card.Title
                      as="h5"
                      className="title-card-information-customer"
                    >
                      Adresa za dostavu
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div className="detail-order-information-address">
                      <div className="detail-order-information-customer-name detail-order-information-customer-item">
                        <h5>Država</h5>
                        <h4>{dataOrderDetail[0]?.country}</h4>
                      </div>
                      <div className="detail-order-information-customer-email detail-order-information-customer-item">
                        <h5>Grad</h5>
                        <h4>{dataOrderDetail[0]?.city}</h4>
                      </div>
                      <div className="detail-order-information-customer-phone detail-order-information-customer-item">
                        <h5>Poštanski broj</h5>
                        <h4>{dataOrderDetail[0]?.postal_code}</h4>
                      </div>
                      <div className="detail-order-information-customer-phone detail-order-information-customer-item">
                        <h5>Adresa</h5>
                        <h4>{dataOrderDetail[0]?.address}</h4>
                      </div>
                      <div className="detail-order-information-customer-phone detail-order-information-customer-item">
                        <h5>Br.stana/sprat/itd.</h5>
                        <h4>{dataOrderDetail[0]?.apartment}</h4>
                      </div>
                      <div className="detail-order-information-customer-phone detail-order-information-customer-item">
                        <h5>Kompanija</h5>
                        <h4>{dataOrderDetail[0]?.company}</h4>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={12} lg={12} md={12}>
                <Card>
                  <Card.Header>
                    <Card.Title
                      as="h5"
                      className="title-card-information-customer"
                    >
                      Isporuka porudžbine
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div className="detail-order-information-address">
                      <div className="detail-order-information-customer-name">
                        <div className="detail-order-information-customr-status">
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Status
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={data.is_delivery}
                              label="Status"
                              name="is_delivery"
                              size="small"
                              onChange={handleInputChangeDelivery}
                            >
                              <MenuItem value={0}>Nije isporučeno</MenuItem>
                              <MenuItem value={1}>Isporučeno</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default OrderDetail;
