import React, { useEffect } from "react";
import { Fragment } from "react";
import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import InputField from "../components/Admin/Reusable/InputField";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { isEmpty, isUndefined } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { baseUrl, basicFormValidationHandler } from "../utils";
import {
  fetchBannerPagesList,
  fetchCreateNewBanner,
  fetchEditBanner,
} from "../actions/banner";
import { useDispatch, useSelector } from "react-redux";

const Banner = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dataPagesDetail } = useSelector((state) => state.bannerReducer);

  const initialState = {
    acTitle: "",
    acSubtitle: "",
    acButton: "",
    acLink: "",
    anStatus: 1,
    acImage: "",
    anPosition: 1,
  };

  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState("");

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSizeInMB = file.size / 1048576; // Konvertujemo veličinu u MB
      if (fileSizeInMB <= 5) {
        setSelectedFile(file);
      } else {
        toast.error(
          "Odabrani dokument je prevelik. Maksimalna veličina je 5MB.",
          { theme: "colored" }
        );
        setData((prevUser) => ({
          ...prevUser,
          acDocument: null,
        }));
        event.target.value = null; // Resetujemo input
      }
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchBannerPagesList(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (dataPagesDetail && Object.keys(dataPagesDetail).length > 0) {
      setData(dataPagesDetail);
    }
  }, [dataPagesDetail]);

  const bannerValidationHandler = () => {
    // const { acTitle } = data;
    // const errorsObject = basicFormValidationHandler({
    //   acTitle,
    // });
    // setErrors(errorsObject);
    // if (!isEmpty(errorsObject))
    //   toast.error("Morate popuniti sva obavezna polja", { theme: "colored" });
    // return !isEmpty(errorsObject);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (bannerValidationHandler()) {
      return;
    }

    const formData = new FormData();

    formData.append("acTitle", data.acTitle);
    formData.append("acSubtitle", data.acSubtitle);
    formData.append("acButton", data.acButton);
    formData.append("acLink", data.acLink);
    formData.append("anStatus", data.anStatus);
    formData.append("anPosition", data.anPosition);
    selectedFile && formData.append("acImage", selectedFile);

    if (id) {
      formData.append("anId", id);
      dispatch(fetchEditBanner(formData, navigate));
    } else {
      dispatch(fetchCreateNewBanner(formData, navigate));
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="Banner" type="banner-forma" />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h1 className="card-title mb-0 flex-grow-1">
                {/* Kreiranje novog pitanja */}
                {id ? `Izmena banera` : "Kreiraj novi baner"}
              </h1>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit} noValidate>
                <div className="row">
                  <div className="col-6">
                    <FormControl fullWidth className="mb-4">
                      <InputLabel id="demo-simple-select-label">
                        Pozicija
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.anPosition}
                        label="Pozicija"
                        name="anPosition"
                        onChange={handleInputChange}
                        size="small"
                      >
                        <MenuItem value="1">
                          Pozicija 1 <small>(280x388)</small>
                        </MenuItem>
                        <MenuItem value="2">
                          Pozicija 2 <small>(1240x300)</small>
                        </MenuItem>
                        <MenuItem value="3">
                          Pozicija 3 <small>(1240x300)</small>
                        </MenuItem>
                        <MenuItem value="4">
                          Pozicija 4 <small>(520x195)</small>
                        </MenuItem>
                        <MenuItem value="5">
                          Pozicija 5 <small>(520x195)</small>
                        </MenuItem>
                        <MenuItem value="6">
                          Pozicija 6 - na pocetnoj stranici pored glavnog
                          slajdera gore desno baner <small>(350x245)</small>
                        </MenuItem>
                        <MenuItem value="7">
                          Pozicija 7 - na pocetnoj stranici pored glavnog
                          slajdera dole levo baner <small>(350x245)</small>
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <InputField
                      required
                      label="Naslov"
                      name="acTitle"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acTitle}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />
                    <InputField
                      required
                      label="Podnaslov"
                      name="acSubtitle"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acSubtitle}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />
                    <InputField
                      required
                      label="Naziv dugmeta"
                      name="acButton"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acButton}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />
                    <InputField
                      label="Link"
                      name="acLink"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acLink}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />
                    <FormControl fullWidth className="mb-4">
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.anStatus}
                        label="Status"
                        name="anStatus"
                        onChange={handleInputChange}
                        size="small"
                      >
                        <MenuItem value="1">Aktivan</MenuItem>
                        <MenuItem value="0">Neaktivan</MenuItem>
                      </Select>
                    </FormControl>
                    {id && (
                      <div className="inseted-document-link">
                        Slika:&nbsp;
                        <div>
                          <img
                            src={baseUrl + "images/" + dataPagesDetail.acImage}
                            className="img-fluid image-preview-block-table-detail"
                            alt="Fuka"
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-6">
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ marginBottom: "10px" }}
                    >
                      Slika <br />
                      <small>
                        <i>
                          (maksimalna veličina slike do <b>5MB</b>)
                        </i>
                      </small>
                      <br />
                      <small>
                        <i>
                          (dozvoljeni formati <b>.jpg, .jpeg, .png, .svg</b>)
                        </i>
                      </small>
                    </InputLabel>
                    <Input
                      required
                      type="file"
                      error={!isUndefined(errors["selectedFile"])}
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.files.length === 0) {
                          setSelectedFile(null);
                        } else {
                          handleFileSelect(e);
                        }
                      }}
                      id="file-input"
                      name="acFileName"
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="file-input"
                      style={{ position: "relative" }}
                    >
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                      >
                        Priložite sliku
                      </Button>
                      {!isUndefined(errors["selectedFile"]) && (
                        <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                          prilog je obavezan!
                        </p>
                      )}
                    </label>
                    {selectedFile && (
                      <div>
                        <br />
                        <table className="table">
                          <tbody>
                            <tr>
                              <td>
                                Odabrana slika: <b>{selectedFile.name}</b>{" "}
                                (Veličina:{" "}
                                {(selectedFile.size / 1048576).toFixed(2)} MB)
                                {selectedFile && (
                                  <div className="preview-image-holder-admin">
                                    <img
                                      src={URL.createObjectURL(selectedFile)}
                                      alt={selectedFile.name}
                                    />
                                  </div>
                                )}
                              </td>
                              <td>
                                <Button
                                  variant="contained"
                                  color="error"
                                  type="button"
                                  size="small"
                                  startIcon={<DeleteIcon />}
                                  onClick={() => {
                                    setSelectedFile(null);
                                    document.getElementById(
                                      "file-input"
                                    ).value = ""; // Resetovanje input polja za datoteku
                                  }}
                                >
                                  Obriši
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p></p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="float-end mt-3">
                  {id ? (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<CheckIcon />}
                    >
                      Potvrda izmene
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<AddIcon />}
                    >
                      Kreiraj
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Banner;
