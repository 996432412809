import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

//ikonice
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

const NavItem = ({ item }) => {
  const [openMenus, setOpenMenus] = useState({});

  const [showMenu, setShowMenu] = useState("");

  const location = window.location;

  const isMenuOpen = (menuKey) => openMenus[menuKey];

  const toggleMenuFunction = (page) => {
    toggleMenu(page);
  };

  const toggleMenu = (menuKey) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [menuKey]: !prevState[menuKey],
    }));
  };

  const menuItemMain = (item) => {
    return (
      <Fragment>
        <FontAwesomeIcon icon={item.acIcon} className="icon-left-menu" />
        <span>{item.acName}</span>
        {item.children?.length > 0 &&
          (isMenuOpen(item.acModule) ? (
            <KeyboardArrowDownIcon className="icon-meun-down" />
          ) : (
            <KeyboardArrowRightIcon className="icon-meun-up" />
          ))}
      </Fragment>
    );
  };

  return (
    <li
      className="nav-item nav-item-main"
      onClick={() => toggleMenuFunction(item.acModule)}
    >
      {item.children?.length > 0 ? (
        <div
          className={`${
            location.pathname.includes("/adminManager/" + item.acPath)
              ? "active-menu-item"
              : ""
          } nav-link menu-link nav-link-main`}
        >
          {location.pathname.includes("/adminManager/" + item.acPath) && (
            <Fragment>
              <span class="shape1"></span>
              <span class="shape2"></span>
            </Fragment>
          )}
          {menuItemMain(item)}
        </div>
      ) : (
        <Link
          to={`/adminManager/${item.acPath}`}
          className={`${
            location.pathname.includes("/adminManager/" + item.acPath)
              ? "active-menu-item"
              : ""
          } nav-link menu-link nav-link-main`}
        >
          {location.pathname.includes("/adminManager/" + item.acPath) && (
            <Fragment>
              <span class="shape1"></span>
              <span class="shape2"></span>
            </Fragment>
          )}
          {menuItemMain(item)}
        </Link>
      )}
      {item.children?.length > 0 && (
        <div
          className={`menu-dropdown collapse ${
            isMenuOpen(item.acModule) ? "show" : ""
          }`}
          id="sidebarDashboards"
        >
          <ul className="nav nav-sm flex-column">
            <li className="nav-item">
              {item.children?.map((item2, key2) => (
                <Link
                  onClick={() => toggleMenuFunction(item.acModule)}
                  to={`/adminManager/${item2.acPath}`}
                  className={`${
                    location.pathname == "/adminManager/" + item2.acPath
                      ? "active-submenu-item"
                      : ""
                  } nav-link`}
                  data-key="t-analytics"
                  key={key2}
                >
                  {item2.acName}
                </Link>
              ))}
            </li>
          </ul>
        </div>
      )}
    </li>
  );
};

export default NavItem;
