import { getLanguageList } from "../constants/apiRoutes";
import { languageActions } from "../reducers/Admin/languageActions";
import apiService from "../utils/configAxios";
import { toast } from "react-toastify";

export const fetchLanguageList = () => {
  return (dispatch) => {
    return apiService
      .get(getLanguageList())
      .then((response) => {
        dispatch(languageActions.fetchLanguagesList(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
