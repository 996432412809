import React, { Fragment, useEffect } from "react";
import {
  statusRenderStatus,
  statusRenderStatusDelivery,
} from "../../utils/cellRenderTable";

import AggridTable from "../../components/Admin/Reusable/AggridTable";
import Breadcrumb from "../../components/Admin/Reusable/Breadcrumb";
import ButtonColumn from "../../components/Admin/Reusable/ButtonColumn";
import LoadingIndicator from "../../components/Admin/Reusable/LoadingIndicator";
import { fetchOrderPagesList } from "../../actions/product";
import { formattedPrice } from "../../utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const OrderList = () => {
  const dispatch = useDispatch();
  const { dataOrderList, loading } = useSelector(
    (state) => state.productReducer
  );

  useEffect(() => {
    dispatch(fetchOrderPagesList());
  }, [dispatch]);

  const customCellRenderer = (params) => (
    <ButtonColumn
      editLink={`/adminManager/order-detail/` + params.data.id}
      textToltip="Detalji"
      value={params}
    />
  );

  //settings za tabelu
  const columnDefs = [
    {
      headerName: "Šifra porudžbine",
      field: "order_code",
      filter: "agTextColumnFilter",
      width: 350,
    },
    {
      headerName: "Datum kreiranja",
      field: "date_created",
      filter: "agDateColumnFilter",
      width: 200,
    },
    {
      headerName: "Datum porudžbine",
      field: "purchase_date",
      filter: "agDateColumnFilter",
      width: 200,
    },
    {
      headerName: "Broj stavki u porudžbini",
      field: "total_order_items",
      filter: "agTextColumnFilter",
      width: 200,
    },
    {
      headerName: "Ukupna suma",
      field: "total_price_sum",
      filter: "agTextColumnFilter",
      width: 150,
      valueGetter: (params) => params.data.total_price_sum,
      valueFormatter: (params) =>
        formattedPrice(params.value ? params.value : 0),
      comparator: (valueA, valueB) => {
        return valueA - valueB; // Poredi numeričke vrednosti za sortiranje
      },
    },
    {
      headerName: "Status",
      field: "payment_status_name",
      filter: "agTextColumnFilter",
      cellRenderer: statusRenderStatus,
      width: 150,
      filterParams: {
        defaultOption: "startsWith",
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Isporuka",
      field: "is_delivery_name",
      filter: "agTextColumnFilter",
      cellRenderer: statusRenderStatusDelivery,
      width: 150,
      filterParams: {
        defaultOption: "startsWith",
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Ime",
      field: "first_name",
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Prezime",
      field: "last_name",
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Zemlja",
      field: "country",
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Grad",
      field: "city",
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Poštanski broj",
      field: "postal_code",
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Adresa",
      field: "address",
      filter: "agTextColumnFilter",
      width: 250,
    },
    {
      headerName: "Broj stana",
      field: "apartment",
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Telefon",
      field: "phone",
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Panel",
      field: "custom",
      cellRenderer: customCellRenderer,
      pinned: "right",
      width: 80,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb title="Pregled porudžbina" type="order-lista" />

      <AggridTable
        rowData={dataOrderList}
        columnDefs={columnDefs}
        paginationPageSize={20}
      />
    </Fragment>
  );
};

export default OrderList;
